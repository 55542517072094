













































































































































































































import { Vue, Component } from "vue-property-decorator";
import { SaleArticle, SalePDF } from "@/types/sales/pdf";

// hier folgen die Bild und PDF Dateien für Sales
@Component
export default class Sale extends Vue {
    private privatePDFs: SalePDF[] = [];
    private businessPDFs: SalePDF[] = [
        {
            name: "Business II. 2024",
            pdf: "./pdfs/BUSINESS_II_2024_DE.pdf",
            img: require("@/assets/pdf/img/BUISNESS_2_2024.png"),
        },
    ];

    private privateArticles: SaleArticle[] = [];

    private businessArticles: SaleArticle[] = [];
    private businessCustomer = false;
}
